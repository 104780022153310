// Component states
import states from './settings-html-modifier.state.js'

// Components
import PrismEditor from 'vue-prism-editor'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import MdiMenuRight from 'vue-material-design-icons/MenuRight.vue'

// Services
import { expandLeftPanel } from '@/services/states/states.service'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Settings-mj-raw'

// Vue@Properties
const props = {
  mjml: Object
}
// Vue@Properties

// Data
const data = function () {
  return {
    states
  }
}

// Vue@subComponents
const components = {
  PrismEditor,
  MdiMenuRight
}
// Vue@subComponents

// Methods
const methods = {

  // Func@toggleDevMode
  /**
   * Activate dev mode
   */
  toggleDevMode () {
    expandLeftPanel()
    this.$set(this.states, 'devMode', !this.states.devMode)
  }
  // Func@toggleDevMode
}

// Vue component syntax
export default {
  name,
  props,
  data,
  methods,
  components
}
